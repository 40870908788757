import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { flockColors } from '@flock/flock-component-library'
import { startCase } from 'lodash'
import {
  AdminOrderOnboardingV2GetDocumentDownloadUrlDocument,
  Core_OrderV3,
  Core_OrderV3Task,
  Core_OrderV3TaskKey,
  Core_OrderV3TaskStatus,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { blueOvermoonTheme, useSnackbar, PopoverMenu } from '@flock/shared-ui'
import { filterOrderV3Tasks } from '@flock/utils'
import { ExpandMore } from '@mui/icons-material'
import {
  Box,
  Typography,
  Button,
  Grid,
  Accordion,
  AccordionSummary,
  Chip,
  AccordionDetails,
  ThemeProvider,
} from '@mui/material'
import { RouteComponentProps, useParams } from '@reach/router'
import React, { useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { FLOCK_INVESTOR_SIMULATOR_URL, GATSBY_ENV } from '../../constants'
import IndividualOrderPageV2 from '../OrderOnboardingV2/IndividualOrderPageV2'
import {
  authorizedSignerProcessUtils,
  LabeledField,
  legalEntityProcessUtils,
  propertyContributionProcessUtils,
  sellingEntityProcessUtils,
  TaskProcessUtils,
} from './individualOrderV3PageUtils'
import useIndividualOrderV3Page from './useIndividualOrderV3Page'

const PageWrapper = styled('div')({
  paddingBottom: '8rem',
})

const PageTitle = styled(Typography)({
  marginTop: '4rem',
  marginBottom: '2rem',
  display: 'flex',
  justifyContent: 'space-between',
})

const StyledSummary = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
})

const statusColors: { [key: string]: string } = {
  completed: flockColors.aquamarine,
  'in progress': flockColors.blue,
  'not started': flockColors.darkGray,
}

const DocumentDownloadButton = (props: {
  label: string
  legalEntityUuid: string
  documentUuid: string
}) => {
  const { label, legalEntityUuid, documentUuid } = props
  const [downloading, setDownloading] = useState(false)
  const { notify } = useSnackbar()
  const { refetch } = useQuery(
    AdminOrderOnboardingV2GetDocumentDownloadUrlDocument,
    {
      skip: true,
    }
  )

  const downloadDocument = async () => {
    try {
      setDownloading(true)
      const urlData = await refetch({
        input: {
          legalEntityUuid,
          documentUuid,
        },
      })
      window.open(
        urlData?.data?.legalEntityDocumentPresignedUrl?.presignedUrl as string,
        '_blank'
      )
    } catch (e) {
      notify(
        'An error while downloading the file. Please refresh or try again.',
        'error'
      )
    }
    setDownloading(false)
  }
  return (
    <Grid item xs={3} flexDirection="column">
      <Button
        variant="secondary"
        onClick={downloadDocument}
        disabled={downloading}
        size="small"
      >
        {label}
      </Button>
    </Grid>
  )
}

export const GetStatusForTasks = (tasks: Core_OrderV3Task[]) => {
  if (
    tasks.every(
      (task) =>
        task.status === Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted
    )
  ) {
    return 'completed'
  } else if (
    tasks.every(
      (task) =>
        task.status === Core_OrderV3TaskStatus.OrderV3TaskStatusNotStarted
    )
  ) {
    return 'not started'
  }

  return 'in progress'
}

type IndividualOrderPageV3Props = RouteComponentProps & {
  orderUuid?: string
}

const IndividualOrderPageV3 = (props: IndividualOrderPageV3Props) => {
  let { orderUuid } = useParams()
  const { orderUuid: orderUuidProp } = props
  if (orderUuidProp) {
    orderUuid = orderUuidProp
  }

  const {
    order,
    legalEntityData,
    loading,
    onSubmit,
    methods,
    isDirty,
    saveDisabled,
    onDelete,
    getDocusignUrl,
    checkOldOrder,
  } = useIndividualOrderV3Page(orderUuid)

  const createOrderSimulatorLink = () =>
    `${FLOCK_INVESTOR_SIMULATOR_URL}/app/simulator/${legalEntityData?.investorAccounts[0]}/app/orders/${order.uuid}`

  const links = [
    {
      key: 'openInvestorViewButton',
      text: 'Open Investor View',
      onClick: () => window.open(createOrderSimulatorLink(), '_blank'),
    },
    {
      key: 'deleteOrderButton',
      text: 'Delete Order',
      onClick: async () => {
        await onDelete()
      },
    },
  ]

  if (loading) {
    return <>Loading...</>
  }

  if (checkOldOrder()) {
    return <IndividualOrderPageV2 orderUuid={orderUuid} />
  } else {
    const contributionAgreementPreparedTasks = filterOrderV3Tasks(
      order as Core_OrderV3,
      {
        keys: [
          Core_OrderV3TaskKey.OrderV3TaskKeyContributionAgreementPreparedAuthorizedSigner,
        ],
      }
    )

    return (
      <ThemeProvider theme={blueOvermoonTheme}>
        <PageWrapper>
          <PageTitle variant="h3">
            Order Tasks
            <Box display="flex" gap="16px">
              <PopoverMenu
                text="Links"
                buttonProps={{
                  sx: {
                    fontWeight: '500',
                  },
                }}
                popoverProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                  },
                  transformOrigin: {
                    horizontal: 'right',
                    vertical: 'top',
                  },
                }}
                actions={links}
              />
              <Button
                disabled={!isDirty || saveDisabled}
                variant="primary"
                onClick={onSubmit}
                size="medium"
              >
                Save
              </Button>
            </Box>
          </PageTitle>
          <Grid container sx={{ marginBottom: '2rem' }}>
            <LabeledField
              label="Date Created"
              value={new Date(order.createdAt).toLocaleDateString('en-US')}
            />
            <LabeledField
              label="Date Updated"
              value={new Date(order.updatedAt).toLocaleDateString('en-US')}
            />
            {order.targetCloseDate && (
              <LabeledField
                label="Target Close Date"
                value={new Date(order.targetCloseDate).toLocaleDateString(
                  'en-US'
                )}
              />
            )}
            {order.contributionAgreementSignedDocumentUuid && (
              <DocumentDownloadButton
                label="Download Contribution Agreement"
                documentUuid={order.contributionAgreementSignedDocumentUuid}
                legalEntityUuid={order.orderLegalEntities[0].legalEntityUuid}
              />
            )}
            {contributionAgreementPreparedTasks.length > 0 &&
              GATSBY_ENV !== 'production' && (
                <a
                  target="_blank"
                  href={getDocusignUrl(contributionAgreementPreparedTasks[0])}
                  rel="noreferrer"
                >
                  Docusign Link
                </a>
              )}
          </Grid>
          <FormProvider {...methods}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <StyledSummary>
                  <Typography variant="h3">Property Contributions</Typography>
                </StyledSummary>
              </AccordionSummary>
              <AccordionDetails>
                {order.orderPropertyContributions.map(
                  (propertyContribution) => {
                    const contributionStatus = GetStatusForTasks(
                      filterOrderV3Tasks(order as Core_OrderV3, {
                        sourceUuids: [
                          propertyContribution.propertyContributionUuid,
                        ],
                      })
                    )
                    return (
                      <Accordion
                        key={propertyContribution.propertyContributionUuid}
                      >
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <StyledSummary>
                            <Typography variant="h3">
                              {propertyContribution.address.formattedAddress}
                            </Typography>
                            <Chip label={startCase(contributionStatus)} />
                          </StyledSummary>
                        </AccordionSummary>
                        {propertyContributionProcessUtils.map(
                          (utils: TaskProcessUtils) => {
                            const {
                              ProcessComponent,
                              processName,
                              processString,
                            } = utils
                            const currentProcess: Core_OrderV3Task[] =
                              propertyContribution[
                                processName as keyof typeof propertyContribution
                              ] as Core_OrderV3Task[]
                            const status = GetStatusForTasks(currentProcess)
                            return (
                              <AccordionDetails key={processName}>
                                <Accordion>
                                  <AccordionSummary expandIcon={<ExpandMore />}>
                                    <StyledSummary>
                                      <Typography variant="h3">
                                        {processString}
                                      </Typography>
                                      <Chip
                                        label={status}
                                        sx={{
                                          textTransform: 'capitalize',
                                          color: 'white!important',
                                          backgroundColor: statusColors[status],
                                          pointerEvents: 'none',
                                        }}
                                      />
                                    </StyledSummary>
                                  </AccordionSummary>
                                  <ProcessComponent tasks={currentProcess} />
                                </Accordion>
                              </AccordionDetails>
                            )
                          }
                        )}
                      </Accordion>
                    )
                  }
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <StyledSummary>
                  <Typography variant="h3">Legal Entities</Typography>
                </StyledSummary>
              </AccordionSummary>
              {order.orderLegalEntities!.map((legalEntity) => {
                const legalEntityStatus = GetStatusForTasks(
                  filterOrderV3Tasks(order as Core_OrderV3, {
                    sourceUuids: [legalEntity.orderLegalEntityUuid],
                  })
                )

                return (
                  <AccordionDetails key={legalEntity.legalEntityUuid}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <StyledSummary>
                          <Typography variant="h3">
                            {legalEntity?.legalEntity?.name}
                          </Typography>
                          <Chip
                            label={legalEntityStatus}
                            sx={{
                              textTransform: 'capitalize',
                              color: 'white!important',
                              backgroundColor: statusColors[legalEntityStatus],
                              pointerEvents: 'none',
                            }}
                          />
                        </StyledSummary>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            Authorized Signers
                          </AccordionSummary>
                          <AccordionDetails>
                            {legalEntity.authorizedSigners!.map((signer) => {
                              const signerTasks = [
                                ...signer.contributionAgreementProcess,
                                ...signer.personalInformationProcess,
                              ]
                              const status = GetStatusForTasks(
                                signerTasks as Core_OrderV3Task[]
                              )
                              return (
                                <AccordionDetails key={signer.email}>
                                  <Accordion>
                                    <AccordionSummary
                                      expandIcon={<ExpandMore />}
                                    >
                                      <StyledSummary>
                                        <Typography variant="h3">
                                          {`${signer.name}, ${signer.email}`}
                                        </Typography>
                                        <Chip
                                          label={status}
                                          sx={{
                                            textTransform: 'capitalize',
                                            color: 'white!important',
                                            backgroundColor:
                                              statusColors[status],
                                            pointerEvents: 'none',
                                          }}
                                        />
                                      </StyledSummary>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      {authorizedSignerProcessUtils.map(
                                        (utils: TaskProcessUtils) => {
                                          const {
                                            ProcessComponent,
                                            processName,
                                            processString,
                                          } = utils
                                          const currentProcess: Core_OrderV3Task[] =
                                            signer[
                                              processName as keyof typeof signer
                                            ] as Core_OrderV3Task[]
                                          const signerStatus =
                                            GetStatusForTasks(currentProcess)
                                          return (
                                            <AccordionDetails key={processName}>
                                              <Accordion>
                                                <AccordionSummary
                                                  expandIcon={<ExpandMore />}
                                                >
                                                  <StyledSummary>
                                                    <Typography variant="h3">
                                                      {processString}
                                                    </Typography>
                                                    <Chip
                                                      label={signerStatus}
                                                      sx={{
                                                        textTransform:
                                                          'capitalize',
                                                        color:
                                                          'white!important',

                                                        backgroundColor:
                                                          statusColors[
                                                            signerStatus
                                                          ],
                                                        pointerEvents: 'none',
                                                      }}
                                                    />
                                                  </StyledSummary>
                                                </AccordionSummary>
                                                <ProcessComponent
                                                  tasks={currentProcess}
                                                  legalEntityUuid={
                                                    legalEntity.legalEntityUuid
                                                  }
                                                />
                                              </Accordion>
                                            </AccordionDetails>
                                          )
                                        }
                                      )}
                                    </AccordionDetails>
                                  </Accordion>
                                </AccordionDetails>
                              )
                            })}
                          </AccordionDetails>
                        </Accordion>
                      </AccordionDetails>
                      <AccordionDetails>
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            Selling Entities
                          </AccordionSummary>
                          <AccordionDetails>
                            {legalEntity.sellingEntities!.map((seller) => {
                              const sellerEntityTasks =
                                seller.sellingEntityProcess
                              const status =
                                GetStatusForTasks(sellerEntityTasks)
                              return (
                                <AccordionDetails key={seller.name}>
                                  <Accordion>
                                    <AccordionSummary
                                      expandIcon={<ExpandMore />}
                                    >
                                      <StyledSummary>
                                        <Typography variant="h3">
                                          {seller.name}
                                        </Typography>
                                        <Chip
                                          label={status}
                                          sx={{
                                            textTransform: 'capitalize',
                                            color: 'white!important',
                                            backgroundColor:
                                              statusColors[status],
                                            pointerEvents: 'none',
                                          }}
                                        />
                                      </StyledSummary>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      {sellingEntityProcessUtils.map(
                                        (utils: TaskProcessUtils) => {
                                          const {
                                            ProcessComponent,
                                            processName,
                                            processString,
                                          } = utils
                                          const currentProcess: Core_OrderV3Task[] =
                                            seller[
                                              processName as keyof typeof seller
                                            ] as Core_OrderV3Task[]
                                          const sellerStatus =
                                            GetStatusForTasks(currentProcess)
                                          return (
                                            <AccordionDetails key={processName}>
                                              <Accordion>
                                                <AccordionSummary
                                                  expandIcon={<ExpandMore />}
                                                >
                                                  <StyledSummary>
                                                    <Typography variant="h3">
                                                      {processString}
                                                    </Typography>
                                                    <Chip
                                                      label={sellerStatus}
                                                      sx={{
                                                        textTransform:
                                                          'capitalize',
                                                        color:
                                                          'white!important',
                                                        backgroundColor:
                                                          statusColors[status],
                                                        pointerEvents: 'none',
                                                      }}
                                                    />
                                                  </StyledSummary>
                                                </AccordionSummary>
                                                <ProcessComponent
                                                  tasks={currentProcess}
                                                  legalEntityUuid={
                                                    legalEntity.legalEntityUuid
                                                  }
                                                />
                                              </Accordion>
                                            </AccordionDetails>
                                          )
                                        }
                                      )}
                                    </AccordionDetails>
                                  </Accordion>
                                </AccordionDetails>
                              )
                            })}
                          </AccordionDetails>
                        </Accordion>
                      </AccordionDetails>
                      {legalEntityProcessUtils.map(
                        (utils: TaskProcessUtils) => {
                          const {
                            ProcessComponent,
                            processName,
                            processString,
                          } = utils
                          const currentProcess: Core_OrderV3Task[] =
                            legalEntity[
                              processName as keyof typeof legalEntity
                            ] as Core_OrderV3Task[]
                          const status = GetStatusForTasks(currentProcess)
                          return (
                            <AccordionDetails key={processName}>
                              <Accordion>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                  <StyledSummary>
                                    <Typography variant="h3">
                                      {processString}
                                    </Typography>
                                    <Chip
                                      label={status}
                                      sx={{
                                        textTransform: 'capitalize',
                                        color: 'white!important',
                                        backgroundColor: statusColors[status],
                                        pointerEvents: 'none',
                                      }}
                                    />
                                  </StyledSummary>
                                </AccordionSummary>
                                <ProcessComponent
                                  tasks={currentProcess}
                                  // legalEntityUuid={order.legalEntityUuid}
                                  // ownerInformationModalOpen={ownerInformationModalOpen}
                                  // setOwnerInformationModalOpen={
                                  //   setOwnerInformationModalOpen
                                  // }
                                />
                              </Accordion>
                            </AccordionDetails>
                          )
                        }
                      )}
                    </Accordion>
                  </AccordionDetails>
                )
              })}
            </Accordion>
          </FormProvider>
        </PageWrapper>
      </ThemeProvider>
    )
  }
}

IndividualOrderPageV3.defaultProps = {
  orderUuid: undefined,
}

export default IndividualOrderPageV3
