import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import useTracking, { TrackingProperties } from '../useTracking/useTracking'
import { TrackingContextProvider } from '../TrackingContextProvider'

export type PageContainerProps = {
  title: string
  metaTags?: any[]
  trackingName?: string
  trackingProperties?: TrackingProperties
  children: React.ReactNode
}

const PageContainer = ({
  title,
  metaTags,
  trackingName,
  trackingProperties,
  children,
}: PageContainerProps) => {
  const [mounted, setMounted] = useState(false)
  const [trackName, setTrackName] = useState(trackingName)
  const { trackPage } = useTracking()

  let name = trackingName
  if (!trackingName && typeof window !== 'undefined') {
    name = window.location.pathname
    if (name !== '/') {
      name = name.replace('/', '')
    }
  }

  useEffect(() => {
    document.body.style.margin = '0'
    document.body.style.fontFamily = 'Outfit'
    setMounted(true)

    if (name) {
      setTrackName(name)
      trackPage(name, trackingProperties)
    }
  }, [name, trackPage, JSON.stringify(trackingProperties)])

  const defaultMetaTags = [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, maximum-scale=5',
      'data-react-helmet': true,
    },
    {
      name: 'format-detection',
      content: 'telephone=no',
    },
  ]
  if (!metaTags) {
    defaultMetaTags.push({
      name: 'description',
      content:
        'Defer capital gains taxes, maintain income and keep your tenants in place with Flock’s rental property exchange options. Seamlessly exchange your rental property for equity in a portfolio of homes managed by Flock.',
      'data-react-helmet': true,
    })
  }

  return (
    <TrackingContextProvider
      name={trackName as string}
      initialTrackingProperties={trackingProperties}
    >
      {mounted && (
        // @ts-ignore
        <Helmet
          htmlAttributes={{
            lang: 'en',
          }}
          title={title}
          meta={metaTags ? [...defaultMetaTags, ...metaTags] : defaultMetaTags}
        />
      )}
      {children}
    </TrackingContextProvider>
  )
}

PageContainer.defaultProps = {
  metaTags: undefined,
}

export default PageContainer
